import React from 'react';
import Iframe from 'react-iframe';

import * as S from './styles';

import SEO from '../components/SEO';
import Layout from '../components/Layout';
import VerticalSlider from '../components/Slide';

export default () => (
  <>
    <SEO title="Home" />
    <Layout>
      <VerticalSlider />
      <S.Title>Faça-nos uma visita</S.Title>
      <S.MapWrapper>
        <Iframe
          title="Mapa como chegar ao Centro Empresarial Montechiari"
          url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3691.6829097046557!2d-42.53752758531017!3d-22.289998285328902!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x978a8be9552b7d%3A0x6cc11262ef9fa5e8!2sEspa%C3%A7o%20Arp!5e0!3m2!1spt-BR!2sbr!4v1579706039999!5m2!1spt-BR!2sbr"
          width="100%"
          height="300"
          frameBorder="0"
          allowFullScreen=""
        />
      </S.MapWrapper>
    </Layout>
  </>
);
