import styled from 'styled-components';

export const Title = styled.h1`
  font-size: 24px;
  font-weight: 300;
  color: var(--first-color);
  font-family: var(--first-font);
  font-style: italic;
  text-transform: uppercase;
  text-align: center;
  padding-top: 40px;
  padding-bottom: 20px;
`;

export const MapWrapper = styled.div`
  padding: 2px 0;
  border-top: 2px solid var(--first-color);
  border-bottom: 5px solid var(--first-color);
`;
