import React from 'react';
import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css';

import BannerFirst from '../../../static/banner1.png';

import { SliderWrapper } from './styles';

const VerticalSlider = () => {
  const params = {
    direction: 'vertical',
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
  };
  return (
    <SliderWrapper>
      <Swiper {...params}>
        <div>
          <img src={BannerFirst} alt="Banner" />
        </div>
        <div>
          <img src={BannerFirst} alt="Banner" />
        </div>
        <div>
          <img src={BannerFirst} alt="Banner" />
        </div>
      </Swiper>
    </SliderWrapper>
  );
};
export default VerticalSlider;
